<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="container" id="container">
        <div class="flex col-center">
          <div class="icon">
            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="50" r="50" fill="black"/>
              <path d="M33.3332 33.3333H66.6665C68.9582 33.3333 70.8332 35.2083 70.8332 37.5V62.5C70.8332 64.7917 68.9582 66.6667 66.6665 66.6667H33.3332C31.0415 66.6667 29.1665 64.7917 29.1665 62.5V37.5C29.1665 35.2083 31.0415 33.3333 33.3332 33.3333Z" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M70.8332 37.5L49.9998 52.0833L29.1665 37.5" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <h2 class="title">Cek Email</h2>
          <div class="text-center text-sm">Doo.id telah mengirimkan link untuk mereset password ke email Anda</div>
        </div>
        <div class="mt-2">
          <ion-button mode="ios" shape="round" expand="block" color="dark" class="btn" @click="$router.push({name: 'Auth'})">Oke</ion-button>
        </div>
      </div>
    </ion-content>
    <ion-footer class="ion-no-border container">
      <ion-toolbar class="text-sm text-center">
        Belum menerima email? Cek halaman spam, atau <a @click.prevent="$router.push({name: 'ForgotPassword'})" class="underline">coba email lainnya</a>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonFooter, IonToolbar, IonButton } from '@ionic/vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonFooter,
    IonToolbar,
    IonButton
  }
})
</script>

<style>
#container {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 20%;
  transform: translateY(-20%);
  padding: 1rem;
}
.underline {
  text-decoration: underline;
  color: #000;
  cursor: pointer;
}
</style>
